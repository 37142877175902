"use client";

import {
  TLangConfig,
  TNavigation,
} from "../../c/[company_name]/_components/_hec/navigation";

// *** Module *** //

/**
 * Represents the navigation menu for different languages.
 */
export const Publicnavigation: TNavigation = {
  fr: [
    { name: "Home", href: "/" },
    { name: "Notre equipe", href: "/notre-equipe" },
    { name: "Nous contacter", href: "/nous-contacter" },
  ],
  en: [
    { name: "Home", href: "/" },
    { name: "Our team", href: "/notre-equipe" },
    { name: "Contact us", href: "/nous-contacter" },
  ],
} as const;

/**
 * Configuration object for language-specific translations.
 */
export const public_Lang_config: TLangConfig = {
  fr: {
    logout: "Déconnexion",
    login: "Connexion",
  },
  en: {
    logout: "Log out",
    login: "Log in",
  },
};
